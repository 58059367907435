<template>
	<div class="content">
		<div class="explain">
			您可以通过网银转账方式使用您的对公账户<span>(仅支持对公账户)</span>付款到
			以下任一账户, 确认您的付款银行告知支付成功后，再查看余额，如果2小时后余额
			仍未更新请联系我们的客服处理！
		</div>
		<div class="bank_card_list" v-if="class_hide">
			<!-- <div class="bank_card" v-for="(item,index) in bank_list" :key="index"> -->
			<div class="bank_card">		
				<div class="item">
					<div class="title">公司名称: </div>
					<!-- <div class="text">{{item.company}}</div>  -->
					<div class="text">安徽省骏功物联网科技有限公司</div>
				</div>
				<div class="item">
					<div class="title">开户银行: </div>
					<!-- <div class="text">{{item.bank_cname}}</div> -->
					<div class="text">徽商银行股份有限公司安庆中兴路支行</div>
				</div>
				<div class="item">
					<div class="title">银行卡号: </div>
					<!-- <div class="text">{{item.card_num}}</div> -->
					<div class="text">225004083101000006</div>
				</div>
				<div class="item">
					<div class="title">联行号: </div>
					<!-- <div class="text">{{item.bank_num}}</div> -->
					<div class="text">319368009138</div>
				</div>
			</div>
		</div>
		<div class="bank_card_list">
			<!-- <div class="bank_card" v-for="(item,index) in bank_list" :key="index"> -->
			<div class="bank_card">		
				<div class="item">
					<div class="title">公司名称: </div>
					<!-- <div class="text">{{item.company}}</div> --> 
					<div class="text">安徽省骏功物联网科技有限公司</div>
				</div>
				<div class="item">
					<div class="title">开户银行: </div>
					<!-- <div class="text">{{item.bank_cname}}</div> -->
					<div class="text">光大银行南京分行营业部</div>
				</div>
				<div class="item">
					<div class="title">银行卡号: </div>
					<!-- <div class="text">{{item.card_num}}</div> -->
					<div class="text"> 76490180802760577</div>
				</div>
				<div class="item">
					<div class="title">联行号: </div>
					<!-- <div class="text">{{item.bank_num}}</div> -->
					<div class="text">303301000501</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapState} from 'vuex'
	export default {
		data() {
			return {

				//银行卡列表
				bank_list:[],
				class_hide:false,
				//这些公司 显示徽商充值信息
				company_list:[
					'南京杰茂物流有限公司',
					'宾阳佳速物流有限公司',
					'新疆融腾达物流有限公司',
					'淄博骏帆物流有限公司',
					'成都陆江货运有限公司',
					'宾阳旗顺物流有限公司',
					'湖北顺心源物流有限公司',
					'武汉市光辉物流有限责任公司湘潭分公司',
					'长兴华畅物流有限公司',
					'郑州盛辉物流有限公司',
					'214'
				]
			}
		},
		created(){

			//读取银行卡列表
			this.get_bank_list();
			if(this.company_list.indexOf(this.company_info.name)!=-1){
				this.class_hide =true
			}
		},
	    computed:{
			...mapState(["company_info",'user_info'])
		},
		methods: {

			//读取银行卡列表
			get_bank_list(){
				
				//尝试取出可用银行卡数据
				let __receiving_bank_list=this.$my.cache.get("__receiving_bank_list");
				if(__receiving_bank_list){
					this.bank_list=__receiving_bank_list
				}else{
					this.get_bank_from_api();
				}
			},

			//去服务器上读取可用银行卡列表
			get_bank_from_api(){
				this.$my.net.req({
					data:{
						mod:'finance',
						ctr:'platform_bankcard_list_shipper',
						use_for_collection:1,//是否用来收款(1:是,2:不是)	
					},
					callback:(data)=>{
						console.log(data)
						//本页数据
						this.bank_list=data.list

						//缓存之
						this.$my.cache.set({
							__receiving_bank_list:[...data.list]
						});
					}
				});
			},
		}
	}
</script>
<style scoped lang="scss">
	.content{
		margin:20px 15px;
		border: 1px solid #ddd;
		border-radius: 10px;
		padding: 15px;
		.explain{
			font-size:12px;
			color:#999;
		}
		.bank_card_list{
			margin-top:10px;
			.bank_card{
				border: 1px dashed #aaa;
				border-radius: 5px;
				padding-top: 7px;
				margin-top: 10px;
				.item{
					height: 30px;
					display: flex;
					font-size: 13px;
					font-weight:800;
					margin: 0;
					.title{
						text-align:center;
						width:100px;
					}
				}
			}
		}
	}
</style>