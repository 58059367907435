 <template>
	<el-dialog title="资金管理" top="1vh" width="1500px" :visible.sync="is_show_in_page" @close="close">
        <div class="page">
             <div class="zwb_list">
                 <div>中物宝</div>
                 <div class="tab_height">
                    <el-table :data="zwb_user" :border="true" :stripe="true" size="small" height="800px" >
                        <el-table-column label="公司名称" width="220">
                            <template slot-scope="scope">
                                <div>{{scope.row.name}}</div>
                                <div v-if="scope.row.company_msg" class="btn">备注:{{scope.row.company_msg}}</div>
                            </template>
                        </el-table-column>
                       <el-table-column label="电话" width="120">
                            <template slot-scope="scope">
                                <div>{{scope.row.app_user_tel}}</div>
                            </template>
                        </el-table-column>
                       <el-table-column label="余额" width="120">
                            <template slot-scope="scope">
                                <div>{{scope.row.recharge_balance}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="120">
                            <template slot-scope="scope">
                               <el-button v-if="scope.row.enter_type" @click="capital_transfer(scope.row,'zwb')" size="mini" type="text">转出</el-button>
                                <el-button v-if="!scope.row.enter_type&&!scope.row.transfer_type" @click="capital_cancel()" size="mini" type="text">取消</el-button>
                               <el-button v-if="scope.row.transfer_type" @click="capital_select(scope.row,'zwb')" size="mini" type="text" style="color:red">转入</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
            <div class="old_list">
                <div>骏功物联</div>
                <div class="tab_height">
                    <el-table :data="orl_list" :border="true" :stripe="true" size="small" height="800px" >
                        <el-table-column label="公司名称" width="220">
                            <template slot-scope="scope">
                                <div>{{scope.row.company_name}}</div>
                                <div v-if="scope.row.company_msg" class="btn">备注:{{scope.row.company_msg}}</div>
                            </template>
                        </el-table-column>
                       <el-table-column label="电话" width="120">
                            <template slot-scope="scope">
                                <div>{{scope.row.tel}}</div>
                            </template>
                        </el-table-column>
                       <el-table-column label="余额" width="120">
                            <template slot-scope="scope">
                                <div>{{scope.row.cargo_balance}}</div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="120">
                            <template slot-scope="scope">
                               <el-button v-if="scope.row.enter_type" @click="capital_transfer(scope.row,'orl')" size="mini" type="text">转出</el-button>
                                 <el-button v-if="!scope.row.enter_type&&!scope.row.transfer_type" @click="capital_cancel()" size="mini" type="text">取消</el-button>
                               <el-button v-if="scope.row.transfer_type" @click="capital_select(scope.row,'orl')" size="mini" type="text" style="color:red">转入</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </div>
        </div>
        <el-dialog title="请输入金额" :visible.sync="dialogFormVisible" :modal='false' width="400px" @close="cancel">
                 <el-input placeholder="" v-model="transfer_adm.much" disabled>
                    <template slot="prepend" style="width:1000px" >当前选中账户余额</template>
                </el-input>
                <el-input placeholder="" disabled v-model="select_adm.much" style="margin-top:10px;">
                    <template slot="prepend" >当前转入账户余额</template>
                </el-input>
                <el-input placeholder="请输入金额" v-model="much" style="margin-top:10px;">
                    <template slot="prepend">转移金额</template>
                </el-input>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="cancel()">取 消</el-button>
                    <el-button type="primary" @click="confirm">确 定</el-button>
                </div>
            </el-dialog>
	</el-dialog>
</template>

<script>
	export default {
		props:{
			is_show:Number,
            company_id:String
		},
		data() {
			return {
                is_show_in_page:false,
                //老系统列表数据
                orl_list:[],
                //中物宝列表数据
                zwb_user:[],
                //转移参数
                transfer_type:false,
                //转出信息
                transfer_adm:{
                    type:'',
                    app_uid:'',
                    much:'',
                },
                //转入信息
                select_adm:{
                    type:'',
                    app_uid:'',
                    much:'',
                },
                dialogFormVisible:false,
                much:'',
			}
		},
		watch:{
			is_show(new_data){
				if(new_data){
                    this.get_page_data()
					this.is_show_in_page=true;

				}else{
					//关闭弹出层
					this.is_show_in_page=false;
				}
			},
		},
		methods: {
            //取消操作
            capital_cancel(){
                this.orl_list.forEach(item=>{
                    item.enter_type =true
                    item.transfer_type = false
                })
                this.zwb_user.forEach(item=>{
                    item.enter_type =true
                    item.transfer_type = false
                })
            },
            //确认
            confirm(){
                
                if(Number(this.much)>Number(this.transfer_adm.much)){
                    this.$my.other.msg({
                        type:'info',
                        str:'当前转出余额大于账号剩余余额'
                    });
                    return
                }
                var transfer_type=''
                if(this.transfer_adm.type == 'zwb' && this.select_adm.type =='orl'){
                    transfer_type =1
                }
                if(this.transfer_adm.type == 'orl' && this.select_adm.type =='zwb'){
                     transfer_type =2
                }
                if(this.transfer_adm.type == 'zwb' && this.select_adm.type =='zwb'){
                    transfer_type =3
                }

                //开始转出
                this.$my.net.req({
                    data:{
                        mod:'api',
                        ctr:'set_recharge',
                        much:this.much,
                        type:transfer_type,
                        pay_uid:this.transfer_adm.app_uid,
                        to_uid:this.select_adm.app_uid,
                        platform:1,
                        company_id:this.company_id
                    },callback:(data)=>{
                        this.$my.other.msg({
                            type:'success',
                            str:'转移成功'
                        });
                        this.get_page_data()
                        this.cancel()
                    }
                })
            },
            //输入金额取消
            cancel(){
                this.transfer_type =false
                this.transfer_adm.much=''
                this.dialogFormVisible =false
                this.orl_list.forEach(item=>{
                    item.enter_type =true
                    item.transfer_type = false
                })
                this.zwb_user.forEach(item=>{
                    item.enter_type =true
                    item.transfer_type = false
                })
            },
            //指定
            capital_select(item,type){
                if(this.transfer_adm.type == 'orl' && type =='orl'){
                    this.$my.other.msg({
                        type:'info',
                        str:'当前不支持骏功物联余额互转'
                    });
                    return
                }
                this.select_adm.type = type
                this.select_adm.app_uid = item.app_uid
                if(type =='zwb'){
                    this.select_adm.much = item.recharge_balance
                }else if(type =='orl'){
                    this.select_adm.much = item.cargo_balance
                }
                this.dialogFormVisible=true
            },
            //资金转移
            capital_transfer(item,type){
                if(type =='zwb'){
                    this.transfer_adm.much = item.recharge_balance
                    this.much = item.recharge_balance
                }else if(type =='orl'){
                    this.transfer_adm.much = item.cargo_balance
                    this.much = item.cargo_balance
                }
                this.transfer_adm.type = type
                
                this.transfer_adm.app_uid = item.app_uid
                // this.transfer_type = !this.transfer_type
                this.orl_list.forEach(data=>{
                    data.enter_type =false
                    data.transfer_type = true
                })
                this.zwb_user.forEach(data=>{
                    data.enter_type =false
                    data.transfer_type = true
                })
                item.enter_type = false
                item.transfer_type = false
            },
            close(){
                this.orl_list=[],
                this.zwb_user=[]
                this.orl_list.forEach(item=>{
                    item.transfer_type = false
                })
                this.zwb_user.forEach(item=>{
                    item.transfer_type = false
                })
            },
            //读取数据
            get_page_data(){
                this.$my.net.req({
                    data:{
                        mod:'api',
                        ctr:'shipper_info',
                        company_id:this.company_id
                    },callback:(res)=>{
                        res.old_user.forEach(item=>{
                            item.enter_type =true
                            item.transfer_type = false
                        })
                        res.zwb_user.forEach(item=>{
                            item.enter_type =true
                            item.transfer_type = false
                        })
                        this.orl_list = res.old_user
                        this.zwb_user = res.zwb_user
                    }
                })
            }
		}
	}
</script>
<style lang="scss" scoped>  
    .page{
        display: flex;
        justify-content: space-around;
    } 

    .btn {
        width: 150px;
        overflow: hidden; //超出的文本隐藏
        text-overflow: ellipsis; //溢出用省略号显示
        white-space: nowrap; // 默认不换行；
    }

    .btn:hover {
        text-overflow: inherit;
        overflow: visible;
        white-space: pre-line;
        /*合并空白符序列，但是保留换行符。*/
    }
</style>