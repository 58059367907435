<template>
	<div class="page">
		<div class="item">
			<div class="my_account">
				<div class="title">我的账户</div>
				<div class="balance_view">
					<span class="balance">{{recharge_balance}}</span>
					<span class="unit">&nbsp;&nbsp;&nbsp;元</span>
				</div>
				<div class="balance_view" v-if="yufu_total>0">
					<span class="balance1">已垫资金额：{{yufu_total}}</span>
					<span class="unit">&nbsp;&nbsp;&nbsp;元</span>
				</div>
				<!-- <div class="title">子账户可分配余额</div>
				<div class="balance_view">
					<span class="balance">{{sub_total_price}}</span>
					<span class="unit">&nbsp;&nbsp;&nbsp;元</span>
				</div> -->
				<div class="btns">
					<div class="btn" @click="actContentTypeChange(1)">
						<div class="icon" style="margin-top: 20px;">&#xe60c;</div>
						<div class="text">支付密码</div>
					</div>
					<div class="shu"></div>
					<div class="btn" @click="actContentTypeChange(2)">
						<div class="icon" style="margin-top: 20px;">&#xe6a6;</div>
						<div class="text">登录密码</div>
					</div>
					<div class="shu"></div>
					<div class="btn" @click="actContentTypeChange(3)">
						<div class="icon" style="margin-top: 20px;">&#xe603;</div>
						<div class="text">充值</div>
					</div>
					<div class="shu"></div>
					<div class="btn" @click="capital_adm">
						 <i class="el-icon-wallet" style="font-size: 26px;margin-top: 18px;color: #409EFF;"></i>
						<div class="text">资金管理</div>
					</div>
				</div>
			</div>
			<actContent :type="actContentType"></actContent>
		</div>
		<div class="item">
			<div class="real_info">
				<div class="title">个人认证({{real_info.real_status_text}})</div>
				<div class="form">
					<el-form status-icon label-position="left" label-width="90px">
						<el-form-item label="用户头像">
							<div class="img_upl_btn">
								<div class="status" v-if="img_upl.headimg.status==1">未上传</div>
								<div class="status" v-if="img_upl.headimg.status==2">已上传未审核</div>
								<div class="status" v-if="img_upl.headimg.status==3">审核通过</div>
								<div class="status" v-if="img_upl.headimg.status==4">审核未通过</div>
								<div class="btn" @click="choose_img('headimg')">
									<img v-if="img_upl.headimg.src" :src="img_upl.headimg.src" class="avatar"
										style="width:140px" />
									<i v-else class="el-icon-plus avatar-uploader-icon" style="width:140px"></i>
								</div>
							</div>
						</el-form-item>
						<el-form-item label="身份证正面">
							<div class="img_upl_btn">
								<div class="status" v-if="img_upl.id_card_img1.status==1">未上传</div>
								<div class="status" v-if="img_upl.id_card_img1.status==2">已上传未审核</div>
								<div class="status" v-if="img_upl.id_card_img1.status==3">审核通过</div>
								<div class="status" v-if="img_upl.id_card_img1.status==4">审核未通过</div>
								<div class="btn" @click="choose_img('id_card_img1')">
									<img v-if="img_upl.id_card_img1.src" :src="img_upl.id_card_img1.src"
										class="avatar" />
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</div>
							</div>
						</el-form-item>
						<el-form-item label="身份证背面">
							<div class="img_upl_btn">
								<div class="status" v-if="img_upl.id_card_img2.status==1">未上传</div>
								<div class="status" v-if="img_upl.id_card_img2.status==2">已上传未审核</div>
								<div class="status" v-if="img_upl.id_card_img2.status==3">审核通过</div>
								<div class="status" v-if="img_upl.id_card_img2.status==4">审核未通过</div>
								<div class="btn" @click="choose_img('id_card_img2')">
									<img v-if="img_upl.id_card_img2.src" :src="img_upl.id_card_img2.src"
										class="avatar" />
									<i v-else class="el-icon-plus avatar-uploader-icon"></i>
								</div>
							</div>
						</el-form-item>
						<el-form-item label="真实姓名">
							<el-input v-model="real_info.name" :disabled="real_info.real_status==2"
								placeholder="请输入真实姓名" clearable></el-input>
						</el-form-item>
						<el-form-item label="身份证号">
							<el-input v-model="real_info.id_card_num" :disabled="real_info.real_status==2"
								placeholder="请输入身份证号" clearable></el-input>
						</el-form-item>
						<el-form-item class="text-center padding-top-xl" v-if="real_info.real_status!=2">
							<el-button @click="real_check_sub" type="primary">提交</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<div class="item">
			<div class="company_info">
				<div class="title">公司认证({{company_info_in_page.check_status_text}})</div>
				<div class="form">
					<el-form 
						status-icon label-position="left" label-width="90px"
						:disabled="company_info_in_page.check_status==2"
					>
						<el-form-item label="营业执照">
							<div class="img_upl_btn">
								<div class="btn" @click="choose_img('company_license_img')">
									<img v-if="img_upl.company_license_img.src" :src="img_upl.company_license_img.src" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon" style="width:140px"></i>
								</div>
							</div>
						</el-form-item>
						<el-form-item label="公司名称">
							<el-input v-model="company_info_in_page.name" placeholder="请输入公司名称" clearable></el-input>
						</el-form-item>
						<el-form-item label="公司法人">
							<el-input v-model="company_info_in_page.legal_person" placeholder="请输入公司法人" clearable></el-input>
						</el-form-item>
						<el-form-item label="法人手机号">
							<el-input v-model="company_info_in_page.legal_tel" placeholder="请输入公司法人手机号" clearable></el-input>
						</el-form-item>
						<el-form-item label="法人身份证">
							<el-input v-model="company_info_in_page.legal_code" placeholder="请输入公司法人身份证号" clearable></el-input>
						</el-form-item>
						<el-form-item label="信用代码">
							<el-input v-model="company_info_in_page.license_code" placeholder="统一社会信用代码" clearable></el-input>
						</el-form-item>
						<el-form-item label="公司地址">
							<el-input v-model="company_info_in_page.addr" placeholder="请输入公司地址" clearable></el-input>
						</el-form-item>
						<el-form-item label="开户行">
							<el-input v-model="company_info_in_page.bank_name" placeholder="请输入开户行" clearable></el-input>
						</el-form-item>
						<el-form-item label="开户行卡号">
							<el-input v-model="company_info_in_page.bank_card" placeholder="请输入开户行卡号" clearable></el-input>
						</el-form-item>
						<el-form-item label="公司电话">
							<el-input v-model="company_info_in_page.tel" placeholder="请输入公司电话" clearable></el-input>
						</el-form-item>
						<el-form-item label="公司备注">
							<el-input v-model="company_info_in_page.company_msg" placeholder="请输入公司备注" clearable></el-input>
						</el-form-item>
						<el-form-item class="text-center padding-top-xl" v-if="company_info_in_page.check_status!=2">
							<el-button @click="company_info_sub" type="primary">提交</el-button>
						</el-form-item>
					</el-form>
				</div>
			</div>
		</div>
		<!-- 资金管理弹窗 -->
		<capitalAdm 
			:is_show="capital_adm_shipper.is_show"
			@init="get_balance_info"
		></capitalAdm>
	</div>
</template>

<script>
	import {mapMutations,mapState} from 'vuex'
	import actContent from './act_content.vue'
	import capitalAdm from './capital_adm.vue'
	export default {
		components: {
			actContent,
			capitalAdm
		},
		data() {
			return {
				//资金管理数据
				capital_adm_shipper:{
					is_show:0,
				},
				//'我的账户'下边区域显示内容
				actContentType: 3, //1:设置,2:登陆密码修改界面,3:充值,4:流水

				//充值余额
				recharge_balance: 0,
				
				yufu_total:0,
				//子账户余额
				sub_total_price:0,
				//服务费设置
				service_info:{
					
					//服务费率
					rate:0,
					
					//服务费结算方式(1:除法,2:乘法)
					type:1,
				},

				//实名信息
				real_info: {

					//姓名
					name: '',

					//身份证号
					id_card_num: '',

					//地址
					addr:'',

					//实名状态(1:未实名,2:实名通过,3:实名失败)
					real_status: 1,

					//实名状态(字符串)
					real_status_text: ''
				},

				//公司信息
				company_info_in_page: {

					//审核状态
					check_status: false,
					check_status_text: '未认证',

					//基本数据
					name: '',
					legal_person: '',
					bank_name: '',
					bank_card: '',
					license_code: '',
					addr: '',
					tel: '',
					legal_tel:'',
					legal_code:'',

					company_msg:'',//公司备注
				},

				//各个图片上传控件
				img_upl: {

					//头像
					headimg: {
						status: 1, //头像上传状态(1:未上传,2:已上传审核中,3:审核通过,4:审核未通过)
						src: '', //图片地址
						key: '', //图片key
						is_choose: false,
						is_upl: false,
						bucket: 'headimg',
						api_send_img_key:"key",
						save_para: {
							mod: 'app_user',
							ctr: 'app_user_upl_headimg'
						},
					},

					//身份证正面
					id_card_img1: {
						status: 1, //头像上传状态(1:未上传,2:已上传审核中,3:审核通过,4:审核未通过)
						src: '', //图片地址
						key: '', //图片key
						is_choose: false,
						is_upl: false,
						bucket: 'idcard1',
						api_send_img_key:"key",
						save_para: {
							mod: 'app_user',
							ctr: 'app_user_upl_id_card_img',
							act_type: 1,
						}
					},

					//身份证背面
					id_card_img2: {
						status: 1, //头像上传状态(1:未上传,2:已上传审核中,3:审核通过,4:审核未通过)
						src: '', //图片地址
						key: '', //图片key
						is_choose: false,
						is_upl: false,
						bucket: 'idcard2',
						api_send_img_key:"key",
						save_para: {
							mod: 'app_user',
							ctr: 'app_user_upl_id_card_img',
							act_type: 2,
						}
					},

					//营业执照
					company_license_img: {
						status: 1, //头像上传状态(1:未上传,2:已上传审核中,3:审核通过,4:审核未通过)
						src: '', //图片地址
						key: '', //图片key
						is_choose: false,
						is_upl: false,
						bucket: 'license',
						api_send_img_key:"license_img",
						save_para: {
							mod: 'app_user',
							ctr: 'company_info_sub_by_app_user',
						}
					},
				},
			}
		},
		computed:{
			...mapState(['shipper_set','user_info','company_info'])
		},
		created() {

			//数据初始化
			this.data_init();

			//刷新余额数据
			this.get_balance_info();

			//左下角操作区域
			let query = this.$route.query
			if (['1', '2', '3'].indexOf(query.actContentType) != -1) {
				this.actContentType = parseInt(query.actContentType)
			}
		},
		methods: {

			...mapMutations(['save_company_info','save_user_info']),
			//资金管理
			capital_adm(){
				// this.capital_adm_shipper.id=item.id
				this.capital_adm_shipper.is_show++
			},
			//识别图片
			img_ocr(img_name){

				if(['headimg','id_card_img2'].indexOf(img_name)!=-1){
					return
				}
				
				//图片类型
				switch(img_name){
					case 'id_card_img1':
						var img_type='id_card';
						var img_url=this.$my.qiniu.make_src('idcard1', this.img_upl.id_card_img1.key)
						break;

					case 'company_license_img':
						var img_type='business_license';
						var img_url=this.$my.qiniu.make_src('license', this.img_upl.company_license_img.key)
						break;
				}
				
				//调用接口
				this.$my.net.req({
					data:{
						mod:'res',
						ctr:'img_ocr',
						img_type:img_type,
						img_url:img_url
					},
					callback:(res)=>{

						//图片类型
						switch(img_name){
							case 'id_card_img1':
								this.real_info.name=res.name
								this.real_info.id_card_num=res.id_card_num
								this.real_info.addr=res.address
								break;

							case 'company_license_img':
								this.company_info_in_page.legal_person=res.legal_person
								this.company_info_in_page.license_code=res.reg_num
								this.company_info_in_page.name=res.company_name
								this.company_info_in_page.addr=res.address
								break;
						}
					}
				});
			},

			//上传图片
			upl_img(img_name) {

				//取出相应对象
				let ctr_obj = this.img_upl[img_name]

				//上传
				this.$my.qiniu.upl({
					bucket: ctr_obj.bucket,
					key: ctr_obj.key, //xxxxxx?v=1 或者 null
					file_path: ctr_obj.src,
					other_para: ctr_obj,
					callback: (data, other_para) => {
						other_para.is_upl = true;
						other_para.key = data.key;
						this.img_upl_success(img_name);
						this.img_ocr(img_name);
					}
				});
			},

			//选择图片
			choose_img(img_name) {

				//取出相应对象
				let ctr_obj = this.img_upl[img_name]

				//选择图片
				this.$my.qiniu.choose_img({
					success: (res) => {
						ctr_obj.src = res.src;
						ctr_obj.is_choose = true;
						ctr_obj.is_upl = false;
						this.upl_img(img_name)
					}
				});
			},

			//上传成功后的后续
			img_upl_success(img_name) {

				//取出相应对象
				let ctr_obj = this.img_upl[img_name]

				//状态判断
				if (!ctr_obj.is_choose) {
					this.$util.msg("未选择图片");
					return;
				}
				if (!ctr_obj.is_upl) {
					this.$util.msg("未上传图片");
					return;
				}

				//构造请求参数
				let req_data = {...ctr_obj.save_para};
				req_data[ctr_obj.api_send_img_key]=ctr_obj.key

				//保存数据
				this.$my.net.req({
					data: req_data,
					callback: (res) => {

						//保存完成后 更新本地用户数据
						ctr_obj.status = 2

						//取出用户数据
						let user_info = this.user_info
						
						//更新用户数据缓存
						switch (img_name) {
							case 'headimg':
								user_info.headimg = ctr_obj.key
								user_info.headimg_status = 2
								this.save_user_info(user_info)
								break;
							case 'id_card_img1':
								user_info.id_card_img1 = ctr_obj.key
								user_info.id_card_img1_status = 2
								this.save_user_info(user_info)
								break;
							case 'id_card_img2':
								user_info.id_card_img2 = ctr_obj.key
								user_info.id_card_img2_status = 2
								this.save_user_info(user_info)
								break;
							case 'company_license_img':
								let company_info = this.company_info_in_page
								company_info.license_img = ctr_obj.key
								this.save_company_info(company_info)
								break;
						}
					}
				});
			},
			
			//'我的账户'下方区域显示内容切换
			actContentTypeChange(type) {
				this.actContentType = type;
			},

			//提交公司信息
			company_info_sub() {

				let form = {};

				if (!this.company_info_in_page.name.trim()) {
					this.$my.other.msg({
						type: 'warning',
						str: "缺少公司名称"
					});
					return;
				}

				if (!this.company_info_in_page.legal_person.trim()) {
					this.$my.other.msg({
						type: 'warning',
						str: "缺少公司法人"
					});
					return;
				}

				if (!this.company_info_in_page.bank_name.trim()) {
					this.$my.other.msg({
						type: 'warning',
						str: "缺少开户行"
					});
					return;
				}

				if (!this.$my.check.is_bank_card(this.company_info_in_page.bank_card)) {
					this.$my.other.msg({
						type: 'warning',
						str: "缺少开户行卡号"
					});
					return;
				}

				if (!this.$my.check.is_bl(this.company_info_in_page.license_code)) {
					this.$my.other.msg({
						type: 'warning',
						str: "缺少信用代码"
					});
					return;
				}

				if (!this.company_info_in_page.addr||!this.company_info_in_page.addr.trim()) {
					this.$my.other.msg({
						type: 'warning',
						str: "缺少公司地址"
					});
					return;
				}

				// if (!this.$my.check.is_tel(this.company_info_in_page.tel)) {
				// 	this.$my.other.msg({
				// 		type: 'warning',
				// 		str: "缺少公司电话"
				// 	});
				// 	return;
				// }

				if (!this.img_upl.company_license_img.key){
					this.$my.other.msg({
						type: 'warning',
						str: "缺少营业执照"
					});
					return;
				}

				//提交
				this.$my.other.confirm({
					content: '已填写完成,确认提交?',
					confirm: () => {
						this.$my.net.req({
							data: {
								mod: 'app_user',
								ctr: 'company_info_sub_by_app_user',
								name: this.company_info_in_page.name,
								legal_person: this.company_info_in_page.legal_person,
								bank_name: this.company_info_in_page.bank_name,
								bank_card: this.company_info_in_page.bank_card,
								license_code: this.company_info_in_page.license_code,
								addr: this.company_info_in_page.addr,
								tel: this.company_info_in_page.tel,
								legal_code: this.company_info_in_page.legal_code,
								legal_tel: this.company_info_in_page.legal_tel,
								company_msg:this.company_info_in_page.company_msg
								//图片上传完成后会自动同步到服务器,无需再次上传
								//license_img:this.company_info_in_page.company_license_img.key
							},
							callback: (data) => {

								// console.log(this.company_info_in_page)

								//修改本地公司数据缓存
								this.save_company_info(this.company_info_in_page)

								//提示
								this.$my.other.msg({
									type: 'success',
									str: "提交成功,审核中"
								});
							}
						});
					}
				});
			},

			//提交实名认证
			real_check_sub() {

				//姓名
				if (!this.real_info.name || this.real_info.name == '未实名') {
					this.$my.other.msg({
						type: 'warning',
						str: "缺少真实姓名"
					});
					return;
				}

				//身份证号
				if (!this.$my.check.id_card_num(this.real_info.id_card_num)) {
					this.$my.other.msg({
						type: 'warning',
						str: "身份证号格式有误"
					});
					return;
				}

				//身份证 正面
				if (!this.img_upl.id_card_img1.key) {
					this.$my.other.msg({
						type: 'warning',
						str: "缺少身份证正面"
					});
					return;
				}

				//身份证 反面
				if (!this.img_upl.id_card_img2.key) {
					this.$my.other.msg({
						type: 'warning',
						str: "缺少身份证反面"
					});
					return;
				}

				this.$my.other.confirm({
					content: '已填写完成,确认提交?',
					confirm: () => {
						this.$my.net.req({
							data: {
								mod: 'app_user',
								ctr: 'app_user_real_check',
								name: this.real_info.name,
								id_card_num: this.real_info.id_card_num,
								addr:this.real_info.addr,

								//相关图片上传完成后会自动保存到服务器
								//headimg:this.real_info.headimg.key,
								//id_card_img1:this.real_info.id_card_img1.key,
								//id_card_img2:this.real_info.id_card_img2.key,
							},
							callback: (data) => {

								//实名状态
								this.real_info.real_status = data.real_status

								//保存用户数据
								this.save_user_info(data)

								//提示
								this.$my.other.msg({
									type: 'success',
									str: "实名通过,身份证图片审核中"
								});
							}
						});
					}
				});
			},

			//刷新余额数据
			get_balance_info(){
				this.$my.net.req({
					data:{
						mod:'app_user',
						ctr:'balance_info_by_appp_user',
					},
					callback:(data)=>{

						//取出用户信息
						let user_info=this.user_info

						//更新用户余额信息
						user_info.balance=data.balance;
						user_info.recharge_balance=data.recharge_balance;
						this.yufu_total=data.yufu_total
						user_info.recharge_balance2=data.recharge_balance2;
						user_info.sub_total_price=data.sub_total_price
						//更新本地用户信息
						this.save_user_info(user_info)

						this.sub_total_price=user_info.sub_total_price
						//更新页面内的显示余额
						this.recharge_balance=user_info.recharge_balance
					},
				});
			},

			//页面数据初始化
			data_init() {

				//实名数据
				this.user_info_init(this.user_info);
				
				//公司认证数据
				this.company_info_init(this.company_info);
				//服务费
				let shipper_set=this.shipper_set
				this.service_info.rate=shipper_set.service_charge_rate;
				this.service_info.type=shipper_set.service_charge_type;
			},
			user_info_init(user_info){
				this.real_info.name = user_info.name;
				this.real_info.id_card_num = user_info.id_card_num;
				this.real_info.real_status = user_info.real_status;
				this.img_upl.headimg.status = user_info.headimg_status

				if (user_info.headimg) {
					this.img_upl.headimg.src = this.$my.qiniu.make_src('headimg', user_info.headimg);
					this.img_upl.headimg.key = user_info.headimg
				}
				this.img_upl.id_card_img1.status = user_info.id_card_img1_status
				if (user_info.id_card_img1) {
					this.img_upl.id_card_img1.src = this.$my.qiniu.make_src('idcard1', user_info.id_card_img1);
					this.img_upl.id_card_img1.key = user_info.id_card_img1
				}
				this.img_upl.id_card_img2.status = user_info.id_card_img2_status
				if (user_info.id_card_img2) {
					this.img_upl.id_card_img2.src = this.$my.qiniu.make_src('idcard2', user_info.id_card_img2);
					this.img_upl.id_card_img2.key = user_info.id_card_img2
				}
				
				//实名认证状态(字符串)
				switch (user_info.real_status) {
					case '1':
						this.real_info.real_status_text = '未实名';
						break;
					case '2':
						this.real_info.real_status_text = '实名通过';
						break;
					case '3':
						this.real_info.real_status_text = '实名未通过';
						break;
				}
			},
			company_info_init(company_info){
				console.log(company_info)
				this.company_info_in_page.check_status = company_info.check_status
				this.company_info_in_page.name = company_info.name;
				this.company_info_in_page.legal_person = company_info.legal_person;
				this.company_info_in_page.bank_name = company_info.bank_name;
				this.company_info_in_page.bank_card = company_info.bank_card;
				this.company_info_in_page.license_code = company_info.license_code;
				this.company_info_in_page.addr = company_info.addr;
				this.company_info_in_page.tel = company_info.tel;
				this.company_info_in_page.legal_tel = company_info.legal_tel
				this.company_info_in_page.legal_code = company_info.legal_code
				if (company_info.license_img) {
					this.img_upl.company_license_img.src = this.$my.qiniu.make_src('license', company_info.license_img);
					this.img_upl.company_license_img.key = company_info.license_img
				}
				
				//公司认证状态(字符串)
				switch (company_info.check_status) {
					case '1':
						this.company_info_in_page.check_status_text = '审核中';
						break;
					case '2':
						this.company_info_in_page.check_status_text = '审核通过';
						break;
					case '3':
						this.company_info_in_page.check_status_text = '审核未通过';
						break;
				}
			}
		}
	}
</script>
<style lang="scss" scoped>
	
	//图片上传按钮
	.img_upl_btn {

		.status {
			color: #999;
		}

		.btn {
			
			cursor: pointer;
		}
	}

	//公司认证
	.company_info {

		.title {
			font-weight: bold;
			font-size: 24px;
			color: #555;
			padding: 20px;
		}

		.form {
			width: 85%;
			margin: auto;
			margin-top: 10px
		}
	}

	//个人认证
	.real_info {

		.title {
			font-weight: bold;
			font-size: 24px;
			color: #555;
			padding: 20px;
		}

		.form {
			width: 85%;
			margin: auto;
			margin-top: 10px
		}
	}

	//我的账户
	.my_account {
		min-height: 100%;

		.title {
			color: #555;
			height: 71px;
			line-height: 71px;
			padding: 0 20px;
		}

		.balance_view {
			text-align: center;
			margin-top: 20px;

			.balance {
				color: #409EFF;
				font-size: 44px;
				font-weight: bold;
			}
			.balance1{
				font-size: 18px;
			}
			.unit {
				font-size: 16px;
				font-weight: bold;
			}
		}

		.btns {
			margin-top: 40px;
			display: flex;
			align-items: flex-start;
			justify-content: space-around;
			width: 75%;
			margin: 40px auto 0 auto;
			border: 1px solid #409EFF;
			border-radius: 80px;
			height: 80px;
			overflow: hidden;
			.shu {
				width: 1px;
				height: 50%;
				margin-top: 5%;
				background-color: #409EFF;
			}

			.btn {
				width: 33%;
				height: 100%;
				cursor: pointer;
				text-align: center;
				.icon {
					font-family: ali_iconfont;
					color: #409EFF;
					font-size: 25px;
					margin-top: 12.2%;
				}

				.text {
					font-size: 14px;
					margin-top: 1.55%;
				}
			}
			.btn_selected{
				background-color: #409EFF;
				color:#fff;

				.icon {
					font-family: ali_iconfont;
					color: #fff;
					font-size: 25px;
					margin-top: 12.2%;
				}
			}
		}
	}

	.page {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		justify-content: space-around;
		height: 100%;

		.item {
			min-width: 400px;
			min-height: 100%;
			width: 32%;
			background-color: #fff;
			border-radius: 10px;
			margin-bottom: 10px;
		}
	}

	//上传相关
	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 220px;
		height: 140px;
		line-height: 140px;
		text-align: center;
	}

	.avatar {
		width: 220px;
		height: 140px;
		display: block;
	}
</style>
