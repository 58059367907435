<template>
	<div class="act_content">

		<!-- <set v-if="type==1"></set> -->
		<payPwd v-if="type==1"></payPwd>

		<loginpwd v-if="type==2"></loginpwd>

		<recharge v-if="type==3"></recharge>
	</div>
</template>

<script>
	// import set from './act/set.vue'
	import loginpwd from './act/login_pwd.vue'
	import payPwd from './act/pay_pwd.vue'
	import recharge from './act/recharge.vue'
	export default {
		props:{
			type:Number,//1:明细,2:登陆密码修改界面,3:充值
		},
		components:{
			// set,
			loginpwd,
			payPwd,
			recharge,
		},
		data() {
			return {
				
			}
		},
		methods: {
		}
	}
</script>